import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import TextForms from './components/TextForms';
import About from './components/About';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useParams
} from "react-router-dom";
import react,{useState} from 'react'
function App() {
  const [mode,setmode] = useState('light');
  const toggleMode = ()=>{
    if(mode === 'light'){
      setmode("dark");
      document.body.style.backgroundColor='#496786';
    }else{
      setmode("light")
      document.body.style.backgroundColor='white';
    }
  }
  return (
    <>
<Navbar title="Sanjeev React App" mode={mode} togglemode={toggleMode}/>
<div className="container my-3">
<Router>
    <Routes>
       <Route path='/' element={<TextForms heading="Please Enter your Text"/>} />
      <Route path="/about" element={<About/>} />
    </Routes>
  </Router>
 
  
  </div>

    </>
  );
}

export default App;
