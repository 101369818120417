import React,{useState} from 'react'

export default function TextForms(props) {
    const [text,setText] = useState('Please enter your text here');
    const handleUpClick = ()=>{
        let newtext = text.toUpperCase();
        setText(newtext);
    }
    const handleOnChange = (event)=>{
        setText(event.target.value)
    }
  return (
    <>
    <div>
      <div className="mb-3">
  <h3>{props.heading}</h3>
  <textarea className="form-control" value={text} onChange={handleOnChange} id="mybox" rows="8"></textarea>
</div>
<button className="btn btn-primary" onClick={handleUpClick}>Convert to Upper case</button>
    </div>
    <div className="container">
      <h1>{text}</h1>
    </div>
    </>
  )
}
